import { Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButton } from '@taiga-ui/core';
import { TuiButtonLoading } from '@taiga-ui/kit';
import { SvgIconComponent } from 'angular-svg-icon';
import { UpperFirstPipe } from '@lancelot-frontend/core';
import { TProvider } from '../auth-providers.component';

@Component({
  selector: 'ffb-auth-provider-button',
  templateUrl: './auth-provider-button.component.html',
  styleUrls: ['./auth-provider-button.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    TuiButton,
    TuiButtonLoading,
    SvgIconComponent,
    UpperFirstPipe,
  ],
})
export class AuthProviderButtonComponent {
  @Input() provider!: TProvider;
  @Input() disabled? = false;
  @Input() loading? = false;
}
