<ng-container *transloco="let t">
  <p>
    {{
      t('auth.linkCredentials.info', {
        email: email,
        providersCount: providers.length
      })
    }}
  </p>
  <div class="center mt2">
    <button
      tuiButton
      type="button"
      class="lowercase"
      appearance="icon"
      size="m"
      (click)="cancel()"
      data-test-id="auth-providers-link-credentials-dialog-cancel-button"
    >
      {{ t('actions.cancel') }}
    </button>
    @for (provider of providers; track provider) {
      <div class="mt1">
        <ffb-auth-provider-button
          [provider]="provider"
          (click)="proceedWith(provider)"
          data-test-id="auth-providers-link-credentials-dialog-confirm-button"
        ></ffb-auth-provider-button>
      </div>
    }
  </div>
</ng-container>
