<ng-container *transloco="let t">
  <button
    tuiButton
    size="m"
    appearance="whiteblock"
    class="full-width lowercase"
    [disabled]="disabled"
    [loading]="loading"
  >
    <div class="p2 absolute left-0">
      <svg-icon src="/assets/icon-set/{{ provider }}.svg"></svg-icon>
    </div>
    {{ t('auth.signInWith', { provider: provider | upperfirst }) }}
  </button>
</ng-container>
